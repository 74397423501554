<template>
  <div>
    <div class="head">
      <div class="box">
        <img src="../../assets/img/business/img-bg.png" class="img" />
      </div>
    </div>
    <div class="content2">
      <div class="top">
        <div class="title">{{ Excellentcases.title }}</div>
        <div class="line"></div>
        <div class="title1">{{ Excellentcases.alias }}</div>
      </div>
      <div class="bom">
        <div class="bom-left" style="margin-right:40px">
          <div class="item" @click="showBox(-1)">
            <div class="box-cen" >
              <img style="height:580px;width: 380px;" src="../../assets/img/build/05.png" />
            </div>
          </div>
        </div>
        <div class="bom-right">
          <div style="margin-right:40px">
            <img
              style="height: 280px; width: 400px;"
              src="../../assets/img/build/02.png"
            />
          </div>
          <div style="margin-right:40px">
            <img
              style="height: 280px; width: 400px;"
              src="../../assets/img/build/03.png"
            />
          </div>
          <div style="margin-right:40px">
            <img
              style="height: 280px; width: 400px;"
              src="../../assets/img/build/04.png"
            />
          </div>
          <div style="margin-right:40px">
            <img
              style="height: 280px; width: 400px;"
              src="../../assets/img/build/01.png"
            />
          </div>
        </div>
      </div>
      <!-- <div class="mc-box" v-if="showModal">
        <div class="modalBox animated bounceIn">
          <div class="modal-head">
            <div></div>
            <img
                src="../../assets/img/business/icon-del.png"
                class="del"
                @click="del"
            />
          </div>
        </div>
      </div> -->
    </div>
    <!--    <floatBar></floatBar>-->
  </div>
</template>

<script>
// import floatBar from "../../components/FloatBar";
import { get, post } from "../../utils/http.js";
import config from "../../config/index";

export default {
  metaInfo: {
    title: "浙江骐骥软软件科技",
    meta: [
      {
        name: "Business",
        content: "业务与案例",
      },
    ],
  },
  components: {},
  props: {},
  data() {
    return {
      curIndex: 0,
      curIndex1: null,
      curIndex0: false,
      showModal: false,
      // showModal: true,
      show0: false,
      // show0: true,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      swiper0: {
        title: "活动策划",
        imgList: [
          { img: require("../../assets/img/business/swiper/1-01.png") },
          { img: require("../../assets/img/business/swiper/1-02.png") },
          { img: require("../../assets/img/business/swiper/1-03.png") },
          { img: require("../../assets/img/business/swiper/1-04.png") },
          { img: require("../../assets/img/business/swiper/1-05.png") },
          { img: require("../../assets/img/business/swiper/1-06.png") },
          { img: require("../../assets/img/business/swiper/1-07.png") },
          { img: require("../../assets/img/business/swiper/1-08.png") },
          { img: require("../../assets/img/business/swiper/1-09.png") },
          { img: require("../../assets/img/business/swiper/1-10.png") },
          { img: require("../../assets/img/business/swiper/1-11.png") },
          { img: require("../../assets/img/business/swiper/1-12.png") },
          { img: require("../../assets/img/business/swiper/1-13.png") },
          { img: require("../../assets/img/business/swiper/1-14.png") },
          { img: require("../../assets/img/business/swiper/1-15.png") },
          { img: require("../../assets/img/business/swiper/1-16.png") },
          { img: require("../../assets/img/business/swiper/1-17.png") },
          { img: require("../../assets/img/business/swiper/1-18.png") },
          { img: require("../../assets/img/business/swiper/1-19.png") },
          { img: require("../../assets/img/business/swiper/1-20.png") },
        ],
      },
      swiper2: {
        title: "创意设计",
        imgList: [
          { img: require("../../assets/img/business/swiper/3-01.png") },
          { img: require("../../assets/img/business/swiper/3-02.png") },
          { img: require("../../assets/img/business/swiper/3-03.png") },
          { img: require("../../assets/img/business/swiper/3-04.png") },
          { img: require("../../assets/img/business/swiper/3-05.png") },
          { img: require("../../assets/img/business/swiper/3-06.png") },
          { img: require("../../assets/img/business/swiper/3-07.png") },
          { img: require("../../assets/img/business/swiper/3-08.png") },
          { img: require("../../assets/img/business/swiper/3-09.png") },
          { img: require("../../assets/img/business/swiper/3-10.png") },
          { img: require("../../assets/img/business/swiper/3-11.png") },
          { img: require("../../assets/img/business/swiper/3-12.png") },
          { img: require("../../assets/img/business/swiper/3-13.png") },
          { img: require("../../assets/img/business/swiper/3-14.png") },
          { img: require("../../assets/img/business/swiper/3-15.png") },
          { img: require("../../assets/img/business/swiper/3-16.png") },
          { img: require("../../assets/img/business/swiper/3-17.png") },
          { img: require("../../assets/img/business/swiper/3-18.png") },
          { img: require("../../assets/img/business/swiper/3-19.png") },
          { img: require("../../assets/img/business/swiper/3-20.png") },
        ],
      },
      swiper3: {
        title: "手绘漫画",
        imgList: [
          { img: require("../../assets/img/business/swiper/4-06.png") },
          { img: require("../../assets/img/business/swiper/4-07.png") },
          { img: require("../../assets/img/business/swiper/4-08.png") },
          { img: require("../../assets/img/business/swiper/4-09.png") },
          { img: require("../../assets/img/business/swiper/4-10.png") },
          { img: require("../../assets/img/business/swiper/4-11.png") },
          { img: require("../../assets/img/business/swiper/4-12.png") },
          { img: require("../../assets/img/business/swiper/4-13.png") },
          { img: require("../../assets/img/business/swiper/4-14.png") },
          { img: require("../../assets/img/business/swiper/4-15.png") },
          { img: require("../../assets/img/business/swiper/4-16.png") },
          { img: require("../../assets/img/business/swiper/4-17.png") },
          { img: require("../../assets/img/business/swiper/4-18.png") },
          { img: require("../../assets/img/business/swiper/4-19.png") },
          { img: require("../../assets/img/business/swiper/4-20.png") },
        ],
      },
      swiper4: {
        title: "运营好文",
        imgList: [
          { img: require("../../assets/img/business/swiper/5-01.png") },
          { img: require("../../assets/img/business/swiper/5-02.png") },
          { img: require("../../assets/img/business/swiper/5-03.png") },
          { img: require("../../assets/img/business/swiper/5-04.png") },
          { img: require("../../assets/img/business/swiper/5-05.png") },
          { img: require("../../assets/img/business/swiper/5-06.png") },
          { img: require("../../assets/img/business/swiper/5-07.png") },
        ],
      },
      swiper5: {
        title: "招聘服务",
        imgList: [
          { img: require("../../assets/img/business/swiper/6-1.png") },
          { img: require("../../assets/img/business/swiper/6-2.png") },
          { img: require("../../assets/img/business/swiper/6-3.png") },
          { img: require("../../assets/img/business/swiper/6-4.png") },
          { img: require("../../assets/img/business/swiper/6-5.png") },
        ],
      },
      swiper6: {
        title: "小程序案例",
        imgList: [
          { img: require("../../assets/img/business/swiper/7-1.png") },
          { img: require("../../assets/img/business/swiper/7-2.png") },
          { img: require("../../assets/img/business/swiper/7-3.png") },
          { img: require("../../assets/img/business/swiper/7-4.png") },
          { img: require("../../assets/img/business/swiper/7-5.png") },
          { img: require("../../assets/img/business/swiper/7-6.png") },
        ],
      },
      Excellent: [],
      Excellentcases: [],
      left: [{ picture: "" }],
      right: [],
    };
  },
  watch: {},
  created() {
    this.toTop();
  },
  mounted() {
    this.lode();
  },
  methods: {
    lode() {
      let data = {
        //请求参数
        username: "t1",
        password: "abc123..",
        code: "zjqj",
      };
      let url = "/login";
      post(url, data).then((res) => {
        localStorage.setItem("token", res.token);
        this.case();
      });
    },
    async case() {
      let caseurl =
        "/portal/article/listByCompanyIdAndClassifyId?companyId=6&classifyId=9";
      await get(caseurl, null).then((res) => {
        this.Excellentcases = res.companyClassify;
        this.Excellent = res.companyArticles;
        for (let i = 0; i < this.Excellent.length; i++) {
          this.Excellent[i].picture =
            config.imageUrl + this.Excellent[i].picture;
        }
        this.left = this.Excellent.slice(0);
        this.right = this.Excellent.slice(1, 5);
        for (let i = 0; i < this.right.length; i++) {
          this.right[i].index = i;
        }
      });
    },
    tabbtn(i) {
      this.curIndex = i;
    },
    mouseOver(i) {
      this.curIndex1 = i;
    },
    mouseLeave() {
      this.curIndex1 = null;
    },
    mouseOver0() {
      this.curIndex0 = true;
    },
    mouseLeave0() {
      this.curIndex0 = false;
    },
    // showBox(i) {
    //   this.show0 = false;
    //   this.show1 = false;
    //   this.show2 = false;
    //   this.show3 = false;
    //   this.show4 = false;
    //   this.show5 = false;
    //   this.show6 = false;
    //   if (i == -1) {
    //     this.show0 = true;
    //   } else if (i == 0) {
    //     this.show1 = true;
    //   } else if (i == 1) {
    //     this.show2 = true;
    //   } else if (i == 2) {
    //     this.show3 = true;
    //   } else if (i == 3) {
    //     this.show4 = true;
    //   } else if (i == 4) {
    //     this.show5 = true;
    //   } else if (i == 5) {
    //     this.show6 = true;
    //   }
    //   this.showModal = true;
    // },
    del() {
      this.showModal = false;
    },
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
  },
};
</script>
<style lang="less" scoped>
.top {
  text-align: center;

  .title {
    color: #000028;
    font-size: 24px;
  }

  .line {
    width: 40px;
    height: 1px;
    background: #ef8200;
    opacity: 1;
    margin: 8px auto 7px;
  }

  .title1 {
    font-size: 14px;
    color: #999999;
  }
}

.head {
  width: 100%;
  height: 368px;
  padding-top: 68px;

  .box {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .img {
      width: 100%;
      height: 300px;
      position: absolute;
      z-index: -1;
    }

    .text {
      font-size: 36px;
      font-weight: bold;
      color: #fff;
    }
  }
}

.content2 {
  padding-top: 30px;

  .bom {
    width: 1427px;
    height: 600px;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 90px;
    display: flex;

    .bom-left {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 380px;
        height: 600px;
        cursor: pointer;

        .item-one {
          position: relative;
          width: 551px;
          height: 600px;

          .item-top {
            .img {
              width: 551px;
              height: 600px;
            }

            .title {
              width: 551px;
              height: 46px;
              line-height: 46px;
              background-color: rgba(0, 0, 0, 0.5);
              text-align: center;
              color: #fff;
              font-size: 16px;
              position: absolute;
              bottom: 0;
            }
          }

          .box {
            position: absolute;
            z-index: 99;
            top: 0;
            width: 380px;
            height: 580px;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;

            .box-cen {
              // width: 118px;
              // height: 118px;
              background-color: rgba(0, 0, 0, 0.8);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;

              img {
                width: 80px;
                height: 80px;
              }
            }
          }
        }
      }
    }

    .bom-right {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 292px;
        height: 300px;
        cursor: pointer;

        .item-one {
          position: relative;
          width: 292px;
          height: 300px;

          .item-top {
            .img {
              width: 292px;
              height: 300px;
            }

            .title {
              width: 292px;
              height: 46px;
              line-height: 46px;
              background-color: rgba(0, 0, 0, 0.5);
              text-align: center;
              color: #fff;
              font-size: 16px;
              position: absolute;
              bottom: 0;
            }
          }

          .box {
            position: absolute;
            z-index: 99;
            top: 0;
            width: 292px;
            height: 300px;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;

            .box-cen {
              width: 88px;
              height: 88px;
              background-color: rgba(0, 0, 0, 0.8);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 60px;
                height: 60px;
              }
            }
          }
        }
      }
    }
  }

  .mc-box {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);

    .modalBox {
      padding: 20px;
      width: 1400px;
      height: 700px;
      background: #fff;
      border-radius: 10px;
      position: fixed;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      .modal-head {
        display: flex;
        justify-content: space-between;

        .del {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
</style>